import React from "react";
import { Link } from "react-router-dom";

function Header() {
  return (
    <header id="stickyHeader">
      <div className="container">
        <div className="top-bar">
          <div className="logo">
            <a href="/">
              <img src={require("../assets/img/feedbazz-dark.png")} />
            </a>
          </div>
          <nav className="right-align">
            <ul>
              <li>
                <a href="/">Ana Sayfa</a>
              </li>
              <li>
                <a href="/why-feedbazz">Neden Feedbazz</a>
              </li>
            </ul>
          </nav>
          <Link
            to="https://partner.feedbazz.com/login"
            className="login-button"
          >
            <span>Giriş Yap</span>
          </Link>
        </div>
      </div>
    </header>
  );
}

export default Header;
