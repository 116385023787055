import React from "react";

function WhyFeedbazz() {
  return (
    <div>
      <section id="pricing" className="gap pricing-plan">
        <div className="container">
          <div className="heading heading-center">
            <h2 style={{ color: "#fff" }}>Neden Feedbazz?</h2>
          </div>
          <div className="row gap no-bottom align-items-center">
            <div className="col-lg-5">
              <div className="join-now">
                <img src={require("../assets/img/1.1.jpeg")} />
              </div>
            </div>
            <div className="col-lg-7">
              <div className="join-now-text">
                <h4>
                  <span style={{ color: "#FF5AE5", fontStyle: "italic" }}>
                    Feedbazz
                  </span>{" "}
                  işletmelerin hizmet kalitesini arttırmaya yardımcı olur.
                </h4>
                <h6>
                  Feedbazz, müşterilerin geri bildirimlerini anlık olarak takip
                  ederek, işletmelerin müşteri memnuniyetini artırmasına
                  yardımcı olur. Bu sayede müşteriler, işletmenin gelişimine
                  katkıda bulunarak, daha iyi hizmet alabilirler.
                </h6>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default WhyFeedbazz;
