import React from "react";
import Footer from "../components/Footer";
import HeaderDark from "../components/HeaderDark";
import SubFooter from "./SubFooter";

function Contact() {
  return (
    <div>
      <HeaderDark />
      <section className="jumbotron breadcumb no-bg">
        <div className="mainbreadcumb breadcumb-padding">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <h1>İletişim</h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container">
        <div className="row contact-content">
          <h3 className="contact-form-header">Bir sorunuz mu var ?</h3>
          <div className="col-md-4 mb-3">
            <div className="form-side">
              <form className="form-contact">
                <input
                  type="text"
                  className="form-control"
                  name="user_name"
                  placeholder="İsim"
                  required
                />
                <input
                  type="email"
                  className="form-control"
                  name="user_email"
                  placeholder="Email"
                  required
                />
                <input
                  type="text"
                  className="form-control"
                  name="user_phone"
                  placeholder="Telefon"
                  required
                />
              </form>
            </div>
          </div>

          <div className="col-md-4 d-flex align-items-center justify-content-center">
            <form className="form-contact">
              <textarea
                name="message"
                className="form-control"
                placeholder="Mesajınız"
                required
              />
            </form>
          </div>

          <div className="col-md-4 d-flex align-items-center justify-content-center">
            <div className="mb-30 text-light">
              <h3>İletişim Bilgileri</h3>
              <address className="s1">
                <span className="text-black">
                  Adalet Mah. Manas Bul. No: 39 İç Kapı No: 2511 Bayraklı/İzmir
                </span>
                <span>
                  <i className="fa fa-envelope-o fa-lg"></i>
                  <span className="btn">hi@feedbazz.com</span>
                </span>
              </address>
            </div>
          </div>
          <div className="col-md-12 d-flex align-items-center justify-content-start ">
            <input
              type="submit"
              id="buttonsent"
              value="Gönder"
              className="col-md-12 btn-contact btn-main color-2 no-change"
            />
          </div>
        </div>
      </section>
      <Footer />
      <SubFooter />
    </div>
  );
}

export default Contact;
