import React from "react";
import HeaderDark from "./HeaderDark";
import Footer from "./Footer";
import SubFooter from "./SubFooter";

function Privacy() {
  return (
    <div>
      <HeaderDark />
      <section className="jumbotron breadcumb no-bg">
        <div className="mainbreadcumb breadcumb-padding">
          <div className="container">
            <div className="row m-10-hor">
              <div className="col-12">
                <h1 className="text-center">Gizlilik Politikası</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container">
        <div className="new-content">
          <h3 className="new-content-header">Gizlilik Politikası</h3>
          <p>
            Feedbazz, müşteri gizliliğini önemsiyor ve bu nedenle, Feedbazz
            uygulamasını kullandığınızda toplanan tüm kişisel bilgilerinizi
            güvende tutmak için gerekli tüm önlemleri alıyoruz. Bu gizlilik
            politikası, Feedbazz uygulaması tarafından toplanan, kullanılan ve
            korunan bilgilerle ilgili açıklamaları içermektedir. Bu politikayı
            dikkatlice okumanızı öneririz.
            <br />
            <br />
            Hangi Bilgiler Toplanır?
            <br />
            <br />
            Feedbazz, müşterilerimizden geri bildirimler toplamak ve analiz
            etmek için kişisel bilgiler toplamaktadır. Kişisel bilgilerinize
            örnek olarak adınız, e-posta adresiniz, şifreniz, telefon numaranız
            ve adresiniz verilebilir.
            <br />
            <br />
            Bilgileriniz Nasıl Kullanılır?
            <br />
            <br />
            Feedbazz, müşterilerimizin deneyimlerini iyileştirmek için toplanan
            kişisel bilgileri kullanır. Müşterilerimize daha iyi hizmet sunmak,
            müşterilerimizle iletişim kurmak ve uygulamamızı geliştirmek için bu
            bilgileri kullanırız.
            <br />
            <br />
            Bilgileriniz Nasıl Korunur?
            <br />
            <br />
            Feedbazz, müşteri bilgilerini kayıp, kötüye kullanım ve değiştirme
            gibi risklere karşı korumak için çeşitli güvenlik önlemleri
            almaktadır. Bu önlemler arasında veri şifreleme, güvenlik duvarları
            ve diğer benzer teknolojiler yer almaktadır.
            <br />
            <br />
            Kişisel Bilgilerinizin Paylaşımı
            <br />
            <br />
            Feedbazz, müşteri bilgilerini kesinlikle üçüncü taraflarla
            paylaşmaz. Ancak, yasal olarak zorunlu olduğu takdirde, kişisel
            bilgilerinizi talep eden yetkili makamlara bilgi verme hakkımızı
            saklı tutarız.
            <br />
            <br />
            Gizlilik Politikasındaki Değişiklikler
            <br />
            <br />
            Feedbazz, zaman zaman gizlilik politikasındaki değişiklikleri yapma
            hakkını saklı tutar. Bu değişiklikler uygulandıktan sonra,
            güncellenmiş politika sitemizde yayınlanacaktır. Değişiklikler
            hakkında bilgilendirilmeniz için e-posta veya uygulama içi bildirim
            gönderebiliriz.
            <br />
            <br />
            Sonuç
            <br />
            Feedbazz, müşteri gizliliği konusunda son derece titizdir ve
            müşterilerimizin kişisel bilgilerini güvenli bir şekilde saklar.
            Gizlilik politikamızı okuyarak, bizimle paylaştığınız bilgilerin
            nasıl kullanıldığını ve korunduğunu anlayabilirsiniz.
          </p>
        </div>
      </section>
      <Footer />
      <SubFooter />
    </div>
  );
}

export default Privacy;
