import React from "react";
import HeaderDark from "./HeaderDark";
import Footer from "./Footer";
import SubFooter from "./SubFooter";

function Cookie() {
  return (
    <div>
      <HeaderDark />
      <section className="jumbotron breadcumb no-bg">
        <div className="mainbreadcumb breadcumb-padding">
          <div className="container">
            <div className="row m-10-hor">
              <div className="col-12">
                <h1 className="text-center">Çerez Yönetimi</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container">
        <div className="new-content">
          <h3 className="new-content-header">Çerez Yönetimi</h3>
          <p>
            Feedbazz, kullanıcılarına en iyi hizmeti sunmak için çerezleri
            kullanmaktadır. Bu çerezler, kullanıcılarımızın deneyimini
            geliştirmemize ve web sitemizi sürekli olarak geliştirmemize
            yardımcı olmaktadır. Bu nedenle, çerez yönetimi Feedbazz'da önemli
            bir konudur.
            <br />
            <br />
            Çerez Nedir?
            <br />
            <br />
            Çerezler, bir web sitesini ziyaret eden kullanıcının bilgisayarına
            yerleştirilen küçük dosyalardır. Bu dosyalar, kullanıcının web
            sitesinde gezinirken deneyimini geliştirmek için kullanılır.
            Örneğin, bir web sitesi, kullanıcının dil tercihini, site ayarlarını
            ve daha birçok bilgiyi çerezler aracılığıyla saklayabilir.
            <br />
            <br />
            Feedbazz'da Kullanılan Çerezler
            <br />
            <br />
            Feedbazz, kullanıcıların deneyimini geliştirmek için çeşitli
            çerezler kullanmaktadır. Bunlar şunları içerir:
            <br />
            <br />
            Gerekli Çerezler: Bu çerezler, web sitesinin doğru bir şekilde
            çalışması için gereklidir. Örneğin, oturum açma bilgileri, dil
            tercihleri ve site ayarları bu çerezler aracılığıyla saklanabilir.
            <br />
            <br />
            Analitik Çerezler: Bu çerezler, web sitesinin performansını ve
            kullanımını ölçmek için kullanılır. Örneğin, hangi sayfaların en çok
            ziyaret edildiği, kullanıcıların hangi özellikleri en çok kullandığı
            ve daha fazlası bu çerezler aracılığıyla takip edilebilir.
            <br />
            <br />
            Pazarlama Çerezleri: Bu çerezler, kullanıcıların reklam görmelerine
            ve ilgi alanlarına göre özelleştirilmiş içerikler sunulmasına olanak
            tanır. Örneğin, kullanıcının önceki aramalarına göre özelleştirilmiş
            reklamlar gösterilebilir.
            <br />
            <br />
            Çerez Yönetimi Nasıl Yapılır?
            <br />
            <br />
            Feedbazz, kullanıcıların gizliliğini korumak için çerez yönetiminde
            şeffaf bir yaklaşım benimsemektedir. Kullanıcılar, çerezlerin
            kullanımı hakkında bilgilendirilir ve istedikleri takdirde çerezleri
            kabul etmeyi veya reddetmeyi seçebilirler.
            <br />
            Kullanıcılar, çerezleri yönetmek için tarayıcı ayarlarını
            değiştirebilirler. Tarayıcı ayarları aracılığıyla çerezleri kabul
            etmeyi, reddetmeyi veya belirli bir sitede çerezlerin kullanımını
            engellemeyi seçebilirler.
          </p>
        </div>
      </section>
      <Footer />
      <SubFooter />
    </div>
  );
}

export default Cookie;
