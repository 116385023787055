import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./assets/style.css";
import "./assets/css/bootstrap.min.css";
import "./assets/css/bootstrap.min.css.map";
import "./assets/css/color.css";
import "./assets/css/fontawesome.min.css";
import "./assets/css/jquery.fancybox.min.css";
import "./assets/css/nice-select.css";
import "./assets/css/owl.carousel.min.css";
import "./assets/css/owl.theme.default.min.css";
import "./assets/css/responsive.css";
import Home from "./components/Home";
import NewsDetail1 from "./components/NewsDetail1";
import NewsDetail2 from "./components/NewsDetail2";
import NewsDetail3 from "./components/NewsDetail3";
import AboutFeedbazz from "./components/AboutFeedbazz";
import Contact from "./components/Contact";
import Kvkk from "./components/kvkk";
import Privacy from "./components/Privacy";
import Cookie from "./components/Cookie";
import About from "./components/About";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route element={<Home />} path="/" />
          <Route element={<AboutFeedbazz />} path="/why-feedbazz" />
          <Route
            element={<NewsDetail1 />}
            path="new/musteri-memnuniyeti-icin-yeni-yaklasim-feedbazz"
          />
          <Route
            element={<NewsDetail2 />}
            path="new/markalar-icin-sube-performans-analizi"
          />
          <Route
            element={<NewsDetail3 />}
            path="new/musterilerin-sesine-kulak-verin"
          />
          <Route element={<Contact />} path="contact" />
          <Route element={<About />} path="about" />
          <Route element={<Kvkk />} path="kvkk" />
          <Route element={<Privacy />} path="gizlilik-politikasi" />
          <Route element={<Cookie />} path="cerez-yonetimi" />
        </Routes>
      </Router>
    </div>
  );
}
export default App;
