import React from "react";

function NewsHome() {
  return (
    <section id="services-new" className="gap services">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-4">
            <div className="service-text-new">
              <img src={require("../assets/img/9.png")} />
              <div className="service-header-new">
                <a href="/new/musteri-memnuniyeti-icin-yeni-yaklasim-feedbazz">
                  <h4>Müşteri Memnuniyeti İçin Yeni Bir Yaklaşım: Feedbazz</h4>
                </a>
              </div>
              {/* <button className="service-button">
                <a href="/">Devamını Oku</a>
              </button>{" "} */}
            </div>
          </div>
          <div className="col-lg-4">
            <div className="service-text-new">
              <img src={require("../assets/img/10.png")} />
              <div className="service-header-new">
                <a href="/new/markalar-icin-sube-performans-analizi">
                  <h4>Markalar İçin Şube Performans Analizi</h4>
                </a>
              </div>
              {/* <button className="service-button">
                <a href="/">Devamını Oku</a>
              </button>{" "} */}
            </div>
          </div>
          <div className="col-lg-4">
            <div className="service-text-new">
              <img src={require("../assets/img/11.png")} />
              <div className="service-header-new">
                <a href="/new/musterilerin-sesine-kulak-verin">
                  <h4>
                    Müşterilerin Sesine Kulak Verin: Feedbazz İle Geri
                    Bildirimlerinizi Değerlendirin
                  </h4>
                </a>
              </div>
              {/* <button className="service-button">
                <a href="/">Devamını Oku</a>
              </button> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default NewsHome;
