import React from "react";
import HeaderDark from "./HeaderDark";
import Footer from "./Footer";
import SubFooter from "./SubFooter";

function About() {
  return (
    <div>
      <HeaderDark />
      <section className="jumbotron breadcumb no-bg">
        <div className="mainbreadcumb breadcumb-padding">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <h1>Feedbazz Hikayesi</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="page-section-wrapper position-relative">
        <div className="page-section page-section-with-arrow background-light">
          <div className="container">
            <div className="page-section-body d-flex justify-content-center">
              <div className="text-center" style={{ width: "70%" }}>
                <h6>
                  Feedbazz, müşterilerin geri bildirimlerini toplamak, analiz
                  etmek ve işletmelerin bu geri bildirimleri performanslarını
                  iyileştirmek için kullanabilecekleri değerli verilere
                  dönüştürmek için geliştirilmiş bir uygulamadır.
                  Müşterilerinizi elde tutmanın yeni yolu olarak
                  tanımlanabilecek Feedbazz, müşterilerinizin isteklerine ve
                  ihtiyaçlarına hızlı ve etkili bir şekilde yanıt vermenizi
                  sağlar.
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-section-wrapper position-relative">
        <div className="page-section  background-dark">
          <div className="page-section-with-image">
            <div className="container">
              <div className="row">
                <div className="col-12 col-lg-5 d-flex justify-content-center">
                  <div class="img-container">
                    <img
                      src={require("../assets/img/about-1.png")}
                      alt="Young Rand"
                      sizes="(min-width: 1366px) 1024px, 640px"
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
                <div className="col-12 col-lg-7 text-left mt-4 mt-md-5 mt-lg-0 page-section-features-content">
                  <div className="page-section-features-content-wrapper h-100 d-flex flex-column justify-content-center">
                    <div
                      id="our-founding"
                      className="page-section-anchor"
                    ></div>

                    <div className="page-section-headline text-left">
                      <h2
                        className="page-section-title"
                        style={{ color: "#fff" }}
                      >
                        2022'de Başlayan Teknoloji Yolculuğumuz: Feedbazz
                      </h2>
                    </div>

                    <div className="page-section-body">
                      <div className="text-left-dark">
                        <p>
                          Feedbazz, 2022 yılında kurulmuş bir girişimdir.
                          İşletmelerin müşteri geri bildirimlerini etkili bir
                          şekilde toplayamadıklarını fark eden bir grup
                          girişimci, bu soruna çözüm olabilecek bir platform
                          geliştirmek için yola çıkmıştır. Ekip işletmelerin
                          performanslarını artırabilmeleri için geri
                          bildirimleri kolayca toplamalarına ve analiz
                          etmelerine olanak sağlayacak bir çözüm üretmek
                          amacıyla Feedbazz'i hayata geçirmiştir.
                        </p>
                      </div>
                    </div>
                    <div className="cta-wrapper d-flex justify-content-start flex-wrap"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-section-wrapper position-relative">
        <div className="page-section page-section-with-arrow background-light">
          <div className="container">
            <div className="row flex-row-reverse">
              <div className="col-12 col-lg-5 d-flex justify-content-center">
                <img
                  className=""
                  src={require("../assets/img/about-2.png")}
                  alt="Happy Mozzers"
                  sizes="(min-width: 1366px) 1024px, 640px"
                  style={{ width: "100%" }}
                />
              </div>
              <div className="col-12 col-lg-7 text-left mt-4 mt-md-5 mt-lg-0 page-section-features-content">
                <div className="page-section-features-content-wrapper h-100 d-flex flex-column justify-content-center">
                  <div className="page-section-headline text-left">
                    <h2 className="page-section-title ">
                      İşletmelere Geri Bildirim Yönetimi Çözümleri Sunan Genç
                      Bir Girişim
                    </h2>
                  </div>
                  <div className="page-section-body">
                    <div className="text-left-light">
                      <p>
                        Feedbazz, teknoloji ve müşteri deneyimi konusunda uzman
                        bir ekibin bir araya gelerek geliştirdiği bir projedir.
                        Ekibimiz, müşterilerin geri bildirimlerinin işletmeler
                        için ne kadar önemli olduğunu anlayan ve bunun üzerine
                        inovatif bir çözüm geliştirmeyi amaçlayan
                        profesyonellerden oluşmaktadır. Müşteri odaklı
                        yaklaşımımız ve teknolojik uzmanlığımız ile
                        müşterilerimiz için en iyi deneyimi sunmayı
                        hedefliyoruz.
                      </p>
                    </div>
                  </div>
                  <div className="cta-wrapper d-flex justify-content-start flex-wrap"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-section-wrapper">
        <div className="page-section background-dark">
          <div className="page-section-with-image">
            <div className="container">
              <div className="row">
                <div className="col-12 col-lg-5 d-flex justify-content-center">
                  <img
                    class=""
                    src={require("../assets/img/about-3.png")}
                    alt="Gold Roger 1"
                    sizes="(min-width: 1366px) 1024px, 640px"
                    style={{ width: "100%" }}
                  />
                </div>
                <div className="col-12 col-lg-7 text-left mt-4 mt-md-5 mt-lg-0 page-section-features-content">
                  <div className="page-section-features-content-wrapper h-100 d-flex flex-column justify-content-center">
                    <div
                      id="our-founding"
                      className="page-section-anchor"
                    ></div>

                    <div className="page-section-headline text-left">
                      <h2
                        className="page-section-title"
                        style={{ color: "#fff" }}
                      >
                        Müşteri Deneyimi Sektöründe Lider Olma Yolunda
                      </h2>
                    </div>
                    <div className="page-section-body">
                      <div className="text-left-dark">
                        <p>
                          Feedbazz, müşteri geri bildirimleri alanında sektör
                          lideri olma yolunda Ar-Ge çalışmalarına hız kesmeden
                          devam ediyor. Ürünlerimizi ve hizmetlerimizi
                          geliştirmek için en son teknolojileri kullanıyor ve
                          müşteri geri bildirimlerini dikkate alarak
                          iyileştirmeler yapıyoruz. Ar-Ge ekibimiz, yenilikçi
                          fikirler üretmek ve mevcut ürünlerimizi daha da
                          geliştirmek için sürekli olarak çalışmalar
                          yürütmektedir. Bu sayede müşterilerimize daha iyi bir
                          deneyim sunmayı hedefliyoruz.
                        </p>
                      </div>
                    </div>
                    <div className="cta-wrapper d-flex justify-content-start flex-wrap"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <SubFooter />
    </div>
  );
}

export default About;
