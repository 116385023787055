import React from "react";

function AboutSection() {
  return (
    <section id="about" className="gap">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="heading">
              <h2>
                Çok Data{" "}
                <span style={{ color: "#FF5AE5", fontStyle: "italic" }}>
                  Çok Öngörü
                </span>
              </h2>
              <p className="about-p-section">
                Müşteri geribildirimlerini işlerken kullandığımız yüzlerce
                metrik sayesinde şubelerinizin performansı hakkında nokta atışı
                öngörülerde bulunun.
              </p>
              <button className="learn-more">Daha Çok Öğren</button>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="row align-items-center count-p">
              <div className="col-6">
                <div className="count-style">
                  <h2>120</h2>
                  <span>metrik</span>
                </div>
                <div className="count-style two">
                  <h2>90</h2>
                  <span>performans endeksi</span>
                </div>
              </div>
              <div className="col-6">
                <div className="count-style three">
                  <h2>120GB</h2>
                  <span>AI kapasitesi</span>
                </div>
                <div className="count-style four">
                  <h2>6GB</h2>
                  <span>veri etiketleme/sn</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutSection;
