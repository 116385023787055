import React from "react";
import HeaderDark from "./HeaderDark";
import Footer from "./Footer";
import SubFooter from "./SubFooter";

function AboutFeedbazz() {
  return (
    <div>
      <HeaderDark />
      <section className="jumbotron breadcumb no-bg">
        <div className="mainbreadcumb breadcumb-padding">
          <div className="container">
            <div className="row m-10-hor">
              <div className="col-12">
                <h1 className="text-center">
                  Neden{" "}
                  <span style={{ color: "#FF5AE5", fontStyle: "italic" }}>
                    Feedbazz?
                  </span>
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="about about--white">
        <div
          className="about-white-img"
          style={{ background: "#2CFFFF" }}
        ></div>
        <div className="about-container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="why-feedbazz-header">
                <h2>
                  Feedbazz işletmelerin hizmet kalitesini arttırmaya yardımcı
                  olur.
                </h2>
                <p>
                  Feedbazz, müşterilerin geri bildirimlerini anlık olarak takip
                  ederek, işletmelerin müşteri memnuniyetini artırmasına
                  yardımcı olur. Bu sayede müşteriler, işletmenin gelişimine
                  katkıda bulunarak, daha iyi hizmet alabilirler.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="about-title-type">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="about-title-text">
                <h2>Takip</h2>
                <p>
                  İşletmelerin sürekli gelişmesi için müşteri geri bildirimleri
                  önemlidir. Feedbazz, anlık geri bildirim takibi ve performans
                  artırma imkanı sunar. Ayrıca, şube başarılarını değerlendirmek
                  için şubeler arası raporlar sağlar. Bu, diğer şubeler için
                  başarı stratejilerini belirlemek için kullanılabilir.
                </p>
              </div>
            </div>
            <div className="col-lg-2 d-none d-lg-block">
              <div className="separate"></div>
            </div>
            <div className="col-lg-5 mt-sm">
              <div className="about-title-text">
                <h2>Memnuniyet</h2>
                <p>
                  Feedbazz, işletmelere müşteri geri bildirimlerini takip ederek
                  müşteri memnuniyetini artırma fırsatı sunar. Memnuniyet
                  yükseldikçe tekrar ziyaret olasılığı ve gelir artar. Ayrıca,
                  memnun müşteriler işletmeyi daha fazla kişiye önerir.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="about-title-type-1-1">
        <div className="container">
          <div className="row">
            <div className="col-lg-2 d-none d-lg-block">
              <div className="separate-type-1-1"></div>
            </div>
            <div className="col-lg-10">
              <div className="title-text">
                <h2>Müşteri Memnuniyeti</h2>
                <h3>
                  Markanız İçin{" "}
                  <span style={{ fontStyle: "italic", color: "#2CFFFF" }}>
                    En Önemli Parametre
                  </span>
                </h3>
                <p>
                  Müşteri memnuniyeti, sadakat, tavsiye oranı ve itibar gibi
                  birçok önemli faktörün belirleyicisidir. Müşterilerinizden
                  gelen olumlu geri bildirimler, markanızın itibarını artırmak
                  için kullanılabilir. Ayrıca, müşteri memnuniyetinin artması,
                  müşterilerin markanızın ürünlerine veya hizmetlerine sadık
                  kalmasını sağlayarak, yeniden satın alma oranlarınızı
                  artırabilir.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="about-title-type-1-2 bg-light_grey">
        <div className="container">
          <div className="row">
            <div className="col-lg-2 d-none d-lg-block">
              <div className="separate-type-1-2"></div>
            </div>
            <div className="col-lg-10">
              <div className="title-text">
                <h2>
                  Daha fazla bilgi veya destek için bizimle iletişime geçmekten
                  çekinmeyin
                </h2>
              </div>
              <div>
                <button className="btn-about-contact">
                  <a href="/contact">İletişim</a>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <SubFooter />
    </div>
  );
}

export default AboutFeedbazz;
