import React from "react";

function Footer() {
  return (
    <footer>
      <div className="container">
        <div className="footer-text">
          <div className="col-md-3 col-sm-6 col-xs-1">
            <div className="widget">
              <h5>Genel</h5>
              <ul>
                <li className="list-item">
                  <a href="/">Anasayfa</a>
                </li>
                <li className="list-item">
                  <a href="/contact">İletişim</a>
                </li>
                <li className="list-item">
                  <a href="/about">Hakkımızda</a>
                </li>
                <li className="list-item">
                  <a href="/why-feedbazz">Neden Feedbazz?</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 col-xs-1">
            <div className="widget">
              <h5>Gizlilik</h5>
              <ul>
                <li className="list-item">
                  <a href="/kvkk">KVKK</a>
                </li>
                <li className="list-item">
                  <a href="/gizlilik-politikasi">Gizlilik Politikası</a>
                </li>
                <li className="list-item">
                  <a href="/cerez-yonetimi">Çerez Yönetimi</a>
                </li>
                <li className="list-item">
                  <a href="/">Destek</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 col-xs-1">
            <div className="widget">
              <h5>Diğer</h5>
              <ul>
                <li className="list-item">
                  <a href="/">İstatistikler</a>
                </li>
                <li className="list-item">
                  <a href="/">FeedbazzLab</a>
                </li>
                <li className="list-item">
                  <a href="/career">Kariyer</a>
                </li>
                <li className="list-item">
                  <a href="/">Blog</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 col-xs-1">
            <div className="widget">
              <h5>Bülten</h5>
              <p>Bültenimize kayıt olup son gelişmelerden haberdar olun!</p>
              <div className="mb-10">
                <form
                  action="#"
                  className="row form-dark"
                  id="form_subscribe"
                  method="post"
                  name="form_subscribe"
                >
                  <div className="col text-center d-flex mb-10">
                    <input
                      className="form-control"
                      id="txt_subscribe"
                      name="txt_subscribe"
                      placeholder="mail adresinizi giriniz."
                      type="text"
                    />
                    <a href="/" id="btn-subscribe">
                      <i className="arrow_right bg-color-secondary"></i>
                    </a>
                    <div className="clearfix"></div>
                  </div>
                </form>
                <div className="spacer-10"></div>
                <small>E-mail'iniz bizimle güvende. We don't spam :)</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
