import React from "react";
import HeaderDark from "./HeaderDark";
import Footer from "./Footer";
import SubFooter from "./SubFooter";

function kvkk() {
  return (
    <div>
      <HeaderDark />
      <section className="jumbotron breadcumb no-bg">
        <div className="mainbreadcumb breadcumb-padding">
          <div className="container">
            <div className="row m-10-hor">
              <div className="col-12">
                <h1 className="text-center">
                  Kişisel Verilerin Korunması Kanunu
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container">
        <div className="new-content">
          <h3 className="new-content-header">
            Kişisel Verilerin Korunması Kanunu
          </h3>
          <p>
            KVKK (Kişisel Verilerin Korunması Kanunu) hükümleri gereği, kişisel
            verilerinizin güvenliği ve gizliliği konusunda azami hassasiyet
            gösteriyoruz. Kişisel verilerinizin işlenmesine ilişkin olarak
            aşağıdaki metinleri inceleyebilirsiniz:
            <br />
            <br />
            Kişisel Verilerin İşlenmesi Hakkında Aydınlatma Metni
            <br />
            Veri Sorumlusuna Başvuru Usul ve Esasları
            <br />
            Kişisel Verilerin Korunması Kanunu Politikası
            <br />
            <br />
            Bu metinlerde yer alan açıklamalar, kişisel verilerinizin nasıl
            işlendiği, hangi amaçlarla toplandığı, kimlerle paylaşılabileceği ve
            ne şekilde korunduğu hakkında detaylı bilgi içermektedir. Ayrıca,
            kişisel verilerinizin işlenmesiyle ilgili olarak haklarınız da bu
            metinlerde belirtilmektedir. Kişisel verilerinizin gizliliği ve
            güvenliği konusunda duyarlılığımızı sürdürerek, KVKK mevzuatına
            uygun şekilde hareket etmeye devam edeceğiz.
          </p>
        </div>
      </section>
      <Footer />
      <SubFooter />
    </div>
  );
}
export default kvkk;
