import React from "react";

function SubFooter() {
  return (
    <div>
      <div className="subfooter">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="de-flex">
                <div className="de-flex-col">
                  <span>
                    <img src={require("../assets/img/feedbazz-dark.png")} />
                    <span className="copy">
                      © Copyright 2023 - Feedbazz - Tüm Hakları Saklıdır.
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubFooter;
