import React from "react";
import { Link } from "react-router-dom";

function HeroSection() {
  return (
    <section className="hero-section">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 left-column">
            <div className="hero-text">
              <h2>
                Geribildirim odaklı iyileştirme hikayeniz{" "}
                <span style={{ color: "#FF5AE5", fontStyle: "italic" }}>
                  burada
                </span>{" "}
                başlıyor.
              </h2>
              <p className="hero-p-section">
                Sadık müşterilerinizi kaybetmeyin ve şubelerinizin neyi başarıp
                başaramadıklarını öğrenin.
              </p>
            </div>
            <div className="hero-button">
              <a href="/why-feedbazz">Keşfet</a>
              <Link to="https://partner.feedbazz.com/login">Şimdi Dene</Link>
            </div>
          </div>
          <div className="col-lg-6 right-column">
            <div className="right-img">
              <img src={require("../assets/img/99e.png")} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeroSection;
