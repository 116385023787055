import React from "react";
import Header from "./Header";
import HeroSection from "./HeroSection";
import AboutSection from "./AboutSection";
import Footer from "./Footer";
import NewsHome from "./NewsHome";
import SubFooter from "./SubFooter";
import Features from "./Features";
import WhyFeedbazz from "./WhyFeedbazz";

function Home() {
  return (
    <div>
      <Header />
      <HeroSection />
      <Features />
      <AboutSection />
      <WhyFeedbazz />
      <NewsHome />
      <Footer />
      <SubFooter />
    </div>
  );
}

export default Home;
