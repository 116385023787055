import React from "react";
import Carousel from "react-bootstrap/Carousel";

function Features() {
  return (
    <div>
      <section id="services" className="gap services">
        <div className="">
          <Carousel
            className="custom-carousel"
            prevIcon={
              <span className="carousel-control-prev-icon" aria-hidden="true" />
            }
            nextIcon={
              <span className="carousel-control-next-icon" aria-hidden="true" />
            }
          >
            <Carousel.Item>
              <div className="row align-items-center px-5">
                <div className="col-lg-6">
                  <img
                    className="d-block w-80 feature-img"
                    src={require("../assets/img/4aa.png")}
                    alt="Geleneksel Yöntemlerin Sınırlarını Aşın"
                  />
                </div>
                <div className="col-lg-6">
                  <div className="service-text">
                    <h4>
                      Geleneksel Yöntemlerin{" "}
                      <span style={{ color: "#FF5AE5", fontStyle: "italic" }}>
                        Sınırlarını
                      </span>{" "}
                      Aşın
                    </h4>
                    <p>
                      Feedbazz, işletmelerin müşteri geri bildirimlerini anlık
                      olarak takip ederek memnuniyeti, sadakati ve itibarı
                      artırdığı bir platformdur. QR kodları ve özelleştirilmiş
                      sorular gibi özelliklerle geleneksel yöntemlere göre daha
                      etkili ve hızlı bir geri bildirim süreci sunar.
                    </p>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="row align-items-center px-5">
                <div className="col-lg-6">
                  <img
                    className="d-block w-80 feature-img"
                    src={require("../assets/img/6a.png")}
                    alt="Geribildirim İstatistikleriyle Sorunlarınıza Hızlıca Müdahale Edin"
                  />
                </div>
                <div className="col-lg-6">
                  <div className="service-text">
                    <h4>
                      Geribildirim İstatistikleriyle Sorunlarınıza{" "}
                      <span style={{ color: "#FF5AE5", fontStyle: "italic" }}>
                        Hızlıca
                      </span>{" "}
                      Müdahale Edin
                    </h4>
                    <p>
                      Feedbazz, müşteri geri bildirimlerini marka itibarını
                      artırmak için kullanmanızı sağlayan bir platformdur.
                      Geleneksel yöntemlere göre daha hızlı ve etkili olan
                      Feedbazz, QR kodları ve özelleştirilmiş sorular gibi
                      özelliklerle müşteri geri bildirimlerini daha verimli hale
                      getirir.
                    </p>
                  </div>
                </div>
              </div>
            </Carousel.Item>
        
          </Carousel>
        </div>
      </section>
    </div>
  );
}

export default Features;
